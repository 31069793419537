<template>
  <div>
    <b-sidebar
      id="sidebar-menu"
      aria-labelledby="sidebar-menu-title"
      no-header
      shadow
      bg-variant="primary"
      text-variant="white"
      backdrop
      backdrop-variant="dark"
    >
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-1 py-1">
          <strong class="mr-auto"></strong>
          <b-button variant="outline-secondary" no-border size="sm" @click="hide">X</b-button>
        </div>
      </template>

      <template #default="{ hide }">
        <div class="p-3">
          <div class="d-flex align-items-center flex-column">
            <h4 id="sidebar-menu">Lojas Express</h4>
            <div class="menu"></div>
          </div>

          <b-nav vertical>
            <b-nav-item to="/home" @click="hide, clearLocalStorageSavedPages()">
              <b-icon icon="house"></b-icon>
              Início
            </b-nav-item>

            <b-nav-item @click="toggleUsersMenu(), toggleAllMenu()" class="menu1-item" :class="{ 'rotate-down': isToggleUsers }">
              <i class="fas fa-id-card-alt"></i>
              Usuários
            </b-nav-item>

            <b-collapse id="Users" v-model="isToggleUsers">
              <b-nav-item to="/users-list" @click="hide, clearLocalStorageSavedPages(), toggleUsersMenu()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>

              <b-nav-item to="/users-manager" @click="hide, clearLocalStorageSavedPages(), toggleUsersMenu()" class="sidebar-sublink">
                <b-icon icon="plus-square-fill"></b-icon>
                Cadastro
              </b-nav-item>
            </b-collapse>

            <b-nav-item @click="toggleStock(), toggleAllMenu()" v-model="isToggleStock" class="menu1-item" :class="{ 'rotate-down': isToggleStock }">
              <i class="fas fa-pallet"></i>
              Estoque
            </b-nav-item>

            <b-collapse id="StockProducts" v-model="isToggleStock">
              <b-nav-item
                @click="toggleStockProducts()"
                v-model="isToggleStockProducts"
                class="menu2-item"
                :class="{ 'rotate-down': isToggleStockProducts }"
                style="color: white !important; margin-left: 1.5rem"
              >
                <i class="far fa-image"></i>
                Produtos
              </b-nav-item>
            </b-collapse>

            <b-collapse id="StockProducts-Search" v-model="isToggleStockProducts">
              <b-nav-item to="/products-list" @click="hide, clearLocalStorageSavedPages(), toggleStock()" class="sidebar-sublink-2">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>
            </b-collapse>

            <b-collapse id="StockAdjustment" v-model="isToggleStock">
              <b-nav-item
                @click="toggleStockAdjustment()"
                v-model="isToggleStockAdjustment"
                class="menu2-item"
                :class="{ 'rotate-down': isToggleStockAdjustment }"
                style="color: white !important; margin-left: 1.5rem"
              >
                <i class="far fa-image"></i>
                Ajustes
              </b-nav-item>
            </b-collapse>

            <b-collapse id="StockAdjustmentSearch" v-model="isToggleStockAdjustment">
              <b-nav-item to="/stock-adjustment-documents" @click="hide, clearLocalStorageSavedPages(), toggleStock()" class="sidebar-sublink-2">
                <b-icon icon="table"></b-icon>
                Consulta Documentos
              </b-nav-item>

              <b-nav-item to="/stock-adjustment-details-items" @click="hide, clearLocalStorageSavedPages(), toggleStock()" class="sidebar-sublink-2">
                <b-icon icon="table"></b-icon>
                Consulta Itens Detalhados
              </b-nav-item>

              <b-nav-item to="/stock-adjustment-items" @click="hide, clearLocalStorageSavedPages(), toggleStock()" class="sidebar-sublink-2">
                <b-icon icon="table"></b-icon>
                Consulta Itens Agrupados
              </b-nav-item>
            </b-collapse>

            <b-nav-item @click="toggleCustomersMenu(), toggleAllMenu()" class="menu1-item" :class="{ 'rotate-down': isToggleCustomers }">
              <i class="fas fa-users"></i>
              Clientes
            </b-nav-item>

            <b-collapse id="Customers" v-model="isToggleCustomers">
              <b-nav-item to="customer-list" @click="hide, clearLocalStorageSavedPages(), toggleCustomersMenu()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>
            </b-collapse>

            <b-collapse id="CustomersExceptionsList" v-model="isToggleCustomers">
              <b-nav-item to="customers-exceptions-list" @click="hide, clearLocalStorageSavedPages(), toggleCustomersMenu()" class="sidebar-sublink">
                <i class="fas fa-user-lock"></i>
                Consulta Lista de Exceções
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="toggleOrdersMenu(), toggleAllMenu()"
              v-model="isToggleOrders"
              class="menu1-item"
              :class="{ 'rotate-down': isToggleOrders }"
            >
              <i class="fas fa-clipboard-list"></i>
              Vendas
            </b-nav-item>

            <b-collapse id="Orders" v-model="isToggleOrders">
              <b-nav-item to="/orders-list" @click="hide, clearLocalStorageSavedPages(), toggleOrdersMenu()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>

              <b-nav-item to="/order-items-list" @click="hide, clearLocalStorageSavedPages(), toggleOrdersMenu()" class="sidebar-sublink">
                <i class="fas fa-list"></i>
                Itens Vendidos
              </b-nav-item>

              <b-nav-item to="/order-payments" @click="hide, clearLocalStorageSavedPages(), toggleOrdersMenu()" class="sidebar-sublink">
                <i class="fa fa-wallet"></i>
                Recebimentos
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="toggleBanners(), toggleAllMenu()"
              v-model="isToggleBanners"
              class="menu1-item"
              :class="{ 'rotate-down': isToggleBanners }"
            >
              <i class="far fa-image"></i>
              Banners
            </b-nav-item>

            <b-collapse id="BannerApp" v-model="isToggleBanners">
              <b-nav-item
                @click="toggleBannerAplicativo()"
                v-model="isToggleBannerApp"
                class="menu2-item"
                :class="{ 'rotate-down': isToggleBannerApp }"
                style="color: white !important; margin-left: 1.5rem"
              >
                <i class="far fa-image"></i>
                Aplicativo
              </b-nav-item>
            </b-collapse>

            <b-collapse id="BannerAppMenu" v-model="isToggleBannerApp">
              <b-nav-item to="/banners-app-list" @click="hide, clearLocalStorageSavedPages(), toggleBanners()" class="sidebar-sublink-2">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>

              <b-nav-item to="/banners-app-manager" @click="hide, clearLocalStorageSavedPages(), toggleBanners()" class="sidebar-sublink-2">
                <b-icon icon="plus-square-fill"></b-icon>
                Cadastro
              </b-nav-item>
            </b-collapse>

            <b-collapse id="BannerTotem" v-model="isToggleBanners">
              <b-nav-item
                @click="toggleBannerTotem()"
                v-model="isToggleBannerTotem"
                class="menu2-item"
                :class="{ 'rotate-down': isToggleBannerTotem }"
                style="color: white !important; margin-left: 1.5rem"
              >
                <i class="far fa-image"></i>
                Totem
              </b-nav-item>
            </b-collapse>

            <b-collapse id="BannerTotemMenu" v-model="isToggleBannerTotem">
              <b-nav-item to="/banners-list" @click="hide, clearLocalStorageSavedPages(), toggleBanners()" class="sidebar-sublink-2">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>

              <b-nav-item to="/banners-manager" @click="hide, clearLocalStorageSavedPages(), toggleBanners()" class="sidebar-sublink-2">
                <b-icon icon="plus-square-fill"></b-icon>
                Cadastro
              </b-nav-item>
            </b-collapse>

            <b-nav-item @click="toggleBot(), toggleAllMenu()" v-model="isToggleBot" class="menu3-5-item" :class="{ 'rotate-down': isToggleBot }">
              <i class="far fa-comment-alt"></i>
              Falas do Totem
            </b-nav-item>

            <b-collapse id="Bot" v-model="isToggleBot">
              <b-nav-item to="/bot-speechs" @click="hide, clearLocalStorageSavedPages(), toggleBot()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta Falas
              </b-nav-item>

              <b-nav-item to="/bot-bounded" @click="hide, clearLocalStorageSavedPages(), toggleBot()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta Vínculos
              </b-nav-item>

              <b-nav-item to="/bot" @click="hide, clearLocalStorageSavedPages(), toggleBot()" class="sidebar-sublink">
                <b-icon icon="plus-square-fill"></b-icon>
                Cadastro
              </b-nav-item>

              <b-nav-item to="/bot-bound" @click="hide, clearLocalStorageSavedPages(), toggleBot()" class="sidebar-sublink">
                <b-icon icon="chat-square-text"></b-icon>
                Vínculo
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="toggleDiscountCoupon(), toggleAllMenu()"
              v-model="isToggleDiscountCoupon"
              class="menu4-item"
              :class="{ 'rotate-down': isToggleDiscountCoupon }"
            >
              <i class="fas fa-tag"></i>
              Cupons de Desconto
            </b-nav-item>

            <b-collapse id="DiscountCoupon" v-model="isToggleDiscountCoupon">
              <b-nav-item to="discount-coupon-list" @click="hide, clearLocalStorageSavedPages(), toggleDiscountCoupon()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>

              <b-nav-item to="discount-coupon-manager" @click="hide, clearLocalStorageSavedPages(), toggleDiscountCoupon()" class="sidebar-sublink">
                <b-icon icon="plus-square-fill"></b-icon>
                Cadastro
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="togglePushCampaign(), toggleAllMenu()"
              v-model="isTogglePushCampaign"
              class="menu3-5-item"
              :class="{ 'rotate-down': isTogglePushCampaign }"
            >
              <i class="fas fa-paper-plane"></i>
              Campanha Push
            </b-nav-item>

            <b-collapse id="PushCampaign" v-model="isTogglePushCampaign">
              <b-nav-item to="push-campaign-list" @click="hide, clearLocalStorageSavedPages(), togglePushCampaign()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>

              <b-nav-item to="push-campaign-manager" @click="hide, clearLocalStorageSavedPages(), togglePushCampaign()" class="sidebar-sublink">
                <b-icon icon="plus-square-fill"></b-icon>
                Cadastro
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="togglePromotions(), toggleAllMenu()"
              v-model="isTogglePromotions"
              class="menu1-5-item"
              :class="{ 'rotate-down': isTogglePromotions }"
            >
              <i class="fas fa-percentage"></i>
              Promoções
            </b-nav-item>

            <b-collapse id="Promotions" v-model="isTogglePromotions">
              <b-nav-item to="promotions" @click="hide, clearLocalStorageSavedPages(), togglePromotions()" class="sidebar-sublink">
                <b-icon icon="table"></b-icon>
                Consulta
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="toggleControl(), toggleAllMenu()"
              v-model="isToggleControl"
              class="menu1-item"
              :class="{ 'rotate-down': isToggleControl }"
            >
              <i class="fas fa-folder-open"></i>
              Controle
            </b-nav-item>

            <b-collapse id="Control" v-model="isToggleControl">
              <b-nav-item to="device-history" @click="hide, clearLocalStorageSavedPages(), toggleControl()" class="sidebar-sublink">
                <i class="fas fa-history"></i>
                Histórico Dispositivos
              </b-nav-item>
            </b-collapse>

            <b-collapse id="Events" v-model="isToggleControl">
              <b-nav-item to="events-history" @click="hide, clearLocalStorageSavedPages(), toggleControl()" class="sidebar-sublink">
                <i class="fas fa-history"></i>
                Histórico Eventos
              </b-nav-item>
            </b-collapse>

            <b-nav-item
              @click="toggleManagement(), toggleAllMenu()"
              v-model="isToggleManagement"
              class="menu1-5-item"
              :class="{ 'rotate-down': isToggleManagement }"
            >
              <i class="fas fa-cog"></i>
              Gerencial
            </b-nav-item>

            <b-collapse id="ManagementGateways" v-model="isToggleManagement">
              <b-nav-item
                @click="toggleManagmentGateways()"
                v-model="isToggleManagmentGateways"
                class="menu2-item"
                :class="{ 'rotate-down': isToggleManagmentGateways }"
                style="color: white !important; margin-left: 1.5rem"
              >
                <i class="far fa-address-book"></i>
                Gateways
              </b-nav-item>
            </b-collapse>

            <b-collapse id="ManagementGatewaysDocuments" v-model="isToggleManagmentGateways">
              <b-nav-item to="/gateway-documents" @click="hide, clearLocalStorageSavedPages(), toggleManagement()" class="sidebar-sublink-2">
                <i class="far fa-file-alt"></i>
                Documentos
              </b-nav-item>
            </b-collapse>

            <b-collapse id="ManagementGatewaysRecognition" v-model="isToggleManagmentGateways">
              <b-nav-item to="/gateway-recognition" @click="hide, clearLocalStorageSavedPages(), toggleManagement()" class="sidebar-sublink-2">
                <i class="far fa-grin-alt"></i>
                Reconhecimento Facial
              </b-nav-item>
            </b-collapse>

            <b-collapse id="ManagementGateways" v-model="isToggleManagement">
              <b-nav-item
                @click="toggleManagmentCodes()"
                v-model="isToggleManagmentCodes"
                class="menu2-item"
                :class="{ 'rotate-down': isToggleManagmentCodes }"
                style="color: white !important; margin-left: 1.5rem"
              >
                <i class="material-symbols-outlined"> 123 </i>
                Códigos
              </b-nav-item>
            </b-collapse>

            <b-collapse id="ManagementGatewaysDocuments" v-model="isToggleManagmentCodes">
              <b-nav-item to="/validate-app-code" @click="hide, clearLocalStorageSavedPages(), toggleManagement()" class="sidebar-sublink-2">
                <b-icon icon="check2-all"></b-icon>

                Ativação Aplicativo
              </b-nav-item>
            </b-collapse>

            <b-nav-item to="/monitoring-pos" @click="hide, clearLocalStorageSavedPages()">
              <b-icon icon="display"></b-icon>
              Monitoramento
            </b-nav-item>

            <b-nav-item to="/applications-list" @click="hide, clearLocalStorageSavedPages()">
              <b-icon icon="tablet"></b-icon>
              Aplicativos
            </b-nav-item>

            <b-nav-item to="/login" @click="hide, clearLocalStorageSavedPages()">
              <i class="fas fa-sign-out-alt"></i>
              Sair
            </b-nav-item>
          </b-nav>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      typeUser: '',
      isToggleUsers: false,
      isToggleProducts: false,
      isToggleCustomers: false,
      isToggleOrders: false,
      isToggleBanners: false,
      isToggleBannerApp: false,
      isToggleBannerTotem: false,
      isToggleBot: false,
      isToggleDiscountCoupon: false,
      isTogglePushCampaign: false,
      isTogglePromotions: false,
      isToggleControl: false,
      isToggleManagement: false,
      isToggleStock: false,
      isToggleStockProducts: false,
      isToggleStockAdjustment: false,
      isToggleManagmentGateways: false,
      isToggleManagmentCodes: false,
      lastMenuClicked: ''
    }
  },

  methods: {
    toggleUsersMenu() {
      this.lastMenuClicked = 'Users'
      this.isToggleUsers = !this.isToggleUsers
    },

    toggleCustomersMenu() {
      this.lastMenuClicked = 'Customers'
      this.isToggleCustomers = !this.isToggleCustomers
    },

    toggleOrdersMenu() {
      this.lastMenuClicked = 'Orders'
      this.isToggleOrders = !this.isToggleOrders
    },

    toggleStock() {
      this.lastMenuClicked = 'Stock'

      this.isToggleStock = !this.isToggleStock

      if (this.isToggleStockProducts) {
        this.isToggleStockProducts = !this.isToggleStockProducts
      }

      if (this.isToggleStockAdjustment) {
        this.isToggleStockAdjustment = !this.isToggleStockAdjustment
      }
    },

    toggleStockProducts() {
      this.isToggleStockProducts = !this.isToggleStockProducts

      if (this.isToggleStockAdjustment) {
        this.isToggleStockAdjustment = !this.isToggleStockAdjustment
      }
    },

    toggleStockAdjustment() {
      this.isToggleStockAdjustment = !this.isToggleStockAdjustment

      if (this.isToggleStockProducts) {
        this.isToggleStockProducts = !this.isToggleStockProducts
      }
    },

    toggleBanners() {
      this.lastMenuClicked = 'Banners'

      this.isToggleBanners = !this.isToggleBanners

      if (this.isToggleBannerApp) {
        this.isToggleBannerApp = !this.isToggleBannerApp
      }

      if (this.isToggleBannerTotem) {
        this.isToggleBannerTotem = !this.isToggleBannerTotem
      }
    },

    toggleBannerAplicativo() {
      this.isToggleBannerApp = !this.isToggleBannerApp

      if (this.isToggleBannerTotem) {
        this.isToggleBannerTotem = !this.isToggleBannerTotem
      }
    },

    toggleBannerAppMenu() {
      this.isToggleBannerApp = !this.isToggleBannerApp
      this.isToggleBanners = !this.isToggleBanners
      this.isToggleBannerTotem = !this.isToggleBannerTotem
    },

    toggleBannerTotem() {
      this.isToggleBannerTotem = !this.isToggleBannerTotem

      if (this.isToggleBannerApp) {
        this.isToggleBannerApp = !this.isToggleBannerApp
      }
    },

    toggleBot() {
      this.lastMenuClicked = 'Bot'
      this.isToggleBot = !this.isToggleBot
    },

    toggleDiscountCoupon() {
      this.lastMenuClicked = 'DiscountCoupon'
      this.isToggleDiscountCoupon = !this.isToggleDiscountCoupon
    },

    togglePushCampaign() {
      this.lastMenuClicked = 'PushCampaign'
      this.isTogglePushCampaign = !this.isTogglePushCampaign
    },

    togglePromotions() {
      this.lastMenuClicked = 'Promotions'
      this.isTogglePromotions = !this.isTogglePromotions
    },

    toggleControl() {
      this.lastMenuClicked = 'Control'
      this.isToggleControl = !this.isToggleControl
    },

    toggleManagement() {
      this.lastMenuClicked = 'Management'
      this.isToggleManagement = !this.isToggleManagement

      if (this.isToggleManagmentGateways) {
        this.isToggleManagmentGateways = !this.isToggleManagmentGateways
      }

      if (this.isToggleManagmentCodes) {
        this.isToggleManagmentCodes = !this.isToggleManagmentCodes
      }
    },

    toggleManagmentGateways() {
      this.isToggleManagmentGateways = !this.isToggleManagmentGateways
    },

    toggleManagmentCodes() {
      this.isToggleManagmentCodes = !this.isToggleManagmentCodes
    },

    toggleAllMenu() {
      if (this.lastMenuClicked != 'Users') {
        this.isToggleUsers = false
      }

      if (this.lastMenuClicked != 'Stock') {
        this.isToggleStock = false
        this.isToggleStockProducts = false
        this.isToggleStockAdjustment = false
      }

      if (this.lastMenuClicked != 'Customers') {
        this.isToggleCustomers = false
      }

      if (this.lastMenuClicked != 'Orders') {
        this.isToggleOrders = false
      }

      if (this.lastMenuClicked != 'Banners') {
        this.isToggleBanners = false
        this.isToggleBannerApp = false
        this.isToggleBannerTotem = false
      }

      if (this.lastMenuClicked != 'Bot') {
        this.isToggleBot = false
      }

      if (this.lastMenuClicked != 'DiscountCoupon') {
        this.isToggleDiscountCoupon = false
      }

      if (this.lastMenuClicked != 'PushCampaign') {
        this.isTogglePushCampaign = false
      }

      if (this.lastMenuClicked != 'Promotions') {
        this.isTogglePromotions = false
      }

      if (this.lastMenuClicked != 'Control') {
        this.isToggleControl = false
      }

      if (this.lastMenuClicked != 'Management') {
        this.isToggleManagement = false
      }
    },

    clearLocalStorageSavedPages() {
      //*** Coupon Discount ***//
      localStorage.removeItem('selectedOptionAppCoupon')
      localStorage.removeItem('selectedDateOptionCoupon')
      localStorage.removeItem('startDateCoupon')
      localStorage.removeItem('endDateCoupon')
      localStorage.removeItem('selectedTypeDiscountOption')
      localStorage.removeItem('selectedRadioCoupon')
      localStorage.removeItem('currentPageCoupon')
      localStorage.removeItem('appsId-CouponsDiscount')
      localStorage.removeItem('startDateCouponDiscount')
      localStorage.removeItem('startTimeCouponDiscount')
      localStorage.removeItem('titleCouponDiscount')
      localStorage.removeItem('descriptionCouponDiscount')
      localStorage.removeItem('expiry')
      localStorage.removeItem('expiryAtCouponDiscount')
      localStorage.removeItem('endTimeCouponDiscount')
      localStorage.removeItem('typeCouponDiscount')
      localStorage.removeItem('valueCouponDiscount')
      localStorage.removeItem('idCustomersCouponDiscount')
      localStorage.removeItem('push')
      localStorage.removeItem('isReusable')

      //*** Promotions ***//
      localStorage.removeItem('selectedOptionCompanyPromotion')
      localStorage.removeItem('descriptionPromotion')
      localStorage.removeItem('selectedRadioPromotion')
      localStorage.removeItem('selectedDatePromotionOption')
      localStorage.removeItem('startDatePromotion')
      localStorage.removeItem('endDatePromotion')
      localStorage.removeItem('currentPagePromotion')

      //*** Campaigns ***//
      localStorage.removeItem('selectedOptionDateCompany')
      localStorage.removeItem('startDateCompany')
      localStorage.removeItem('endDateCompany')
      localStorage.removeItem('selectedOptionFilterCompany')
      localStorage.removeItem('filterCompany')
      localStorage.removeItem('currentPageCampaignCompany')

      //*** Users ***/
      localStorage.removeItem('currentPageUsers')
      localStorage.removeItem('selectedOptionFilterUser')
      localStorage.removeItem('filterUser')
      localStorage.removeItem('redirectUserPermissions')

      //*** App Banner ***/
      localStorage.removeItem('selectedOptionCompanyAppBanner')

      //*** Totem Banner ***//
      localStorage.removeItem('selectedOptionCompanyTotemBanner')

      //*** Search Speechs ***//
      localStorage.removeItem('currentPageSearchSpeechs')
      localStorage.removeItem('selectedOptionCompanySearchSpeechs')
      localStorage.removeItem('selectedOptionSiteSearchSpeechs')
      localStorage.removeItem('selectedOptionFilterSearchSpeechs')
      localStorage.removeItem('filterSearchSpeechs')

      //*** Customer Page ***//
      localStorage.removeItem('selectedOptionAppCustomerPage')
      localStorage.removeItem('selectedOptionCustomerPage')
      localStorage.removeItem('customerCustomerPage')
      localStorage.removeItem('selectedRadioCustomerPage')
      localStorage.removeItem('selectedRadioCustomerBlockCustomerPage')
      localStorage.removeItem('selectedPageCustomerPage')

      //*** Site Products ***//
      localStorage.removeItem('companyIdProductSite')
      localStorage.removeItem('siteIdProductSite')
      localStorage.removeItem('fantasyProductSite')
      localStorage.removeItem('currentPageProductSite')

      //*** Monitoring ***/
      localStorage.removeItem('siteIdMonitoringSite')

      //*** Orders ***/
      localStorage.removeItem('orderId')
      localStorage.removeItem('companyIdOrder')
      localStorage.removeItem('siteIdOrder')
      localStorage.removeItem('filterOption')
      localStorage.removeItem('startDateOrder')
      localStorage.removeItem('endDateOrder')
      localStorage.removeItem('currentPageSales')
      localStorage.removeItem('selectedFilterOptionSales')
      localStorage.removeItem('selectedFilterItem')
      localStorage.removeItem('filter')
    }
  },

  beforeMount() {
    this.typeUser = localStorage.getItem('typeUser')

    this.toggleAllMenu()
  }
}
</script>
<style lang="scss" src="../assets/scss/sidebar.scss" scoped />
