<template>
  <div class="dropzone" @dragover.prevent @drop.prevent="handleDrop" @paste="handlePaste">
    <p>Arraste, solte ou cole uma imagem aqui</p>
    <img v-if="image" :src="image" alt="Imagem pré-visualizada" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: null
    }
  },
  methods: {
    handleDrop(event) {
      const file = event.dataTransfer.files[0]
      if (file && file.type.startsWith('image/')) {
        this.readImage(file)
      }
    },
    handlePaste(event) {
      const items = event.clipboardData.items
      for (let item of items) {
        if (item.type.startsWith('image/')) {
          const file = item.getAsFile()
          this.readImage(file)
          break
        }
      }
    },
    readImage(file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.image = e.target.result // Armazena a imagem em base64
      }
      reader.readAsDataURL(file)
    }
  }
}
</script>

<style>
.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
.dropzone img {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
}
</style>
