import { render, staticRenderFns } from "./OrderPayments.html?vue&type=template&id=e99d384a&scoped=true&external"
import script from "./OrderPayments.js?vue&type=script&lang=js&external"
export * from "./OrderPayments.js?vue&type=script&lang=js&external"
import style0 from "./OrderPayments.scss?vue&type=style&index=0&id=e99d384a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e99d384a",
  null
  
)

export default component.exports