import * as config from '@/config.json'

import { copyToClipboard } from '../../functions/functions'

import Loading from 'vue-loading-overlay'
import LoadingComp from '../../components/LoadingComp.vue'
import axios from 'axios'

export default {
  name: 'GatewayDocument',
  components: {
    Loading,
    LoadingComp
  },
  data() {
    return {
      isLoading: false,
      token: '',
      items: [],
      fields: [
        {
          key: 'id',
          label: 'Tenant ID'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'accessType',
          label: 'Tipo de Acesso'
        },
        {
          key: 'licenseExpiration',
          label: 'Data Expiração'
        },
        {
          key: 'registration',
          label: 'Registro'
        },
        {
          key: 'resellerId',
          label: 'ID Reseller'
        },
        {
          key: 'countryDetailId',
          label: 'ID País'
        }
      ],
      isBusy: false,
      selectedCompanyOption: null,
      optionsCompany: [],
      selectedSiteOption: null,
      optionsSite: [],
      email: '',
      password: '',
      enabledSearchByEmailAndPassword: false
    }
  },

  methods: {
    redirect(ref) {
      this.$router.push(`/${ref}`)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type
      })
    },

    focus(id) {
      document.getElementById(id).focus()
    },

    toggleCollapse(id) {
      this.clearCompanyFilter()
      this.clearSiteFilter()
      this.clearEmailFilter()
      this.clearPasswordFilter()

      this.enabledSearchByEmailAndPassword = !this.enabledSearchByEmailAndPassword

      this.$root.$emit('bv::toggle::collapse', id)

      setTimeout(() => {
        this.focus('email')
      }, 0)
    },

    async copyTenantId(data) {
      await copyToClipboard(data.item.id)

      this.getNotification('Sucesso', 'Tenant ID copiado.', 'success')
    },

    clearEmailFilter() {
      this.email = ''
      this.focus('email')
    },

    clearPasswordFilter() {
      this.password = ''
      this.focus('password')
    },

    clearCompanyFilter() {
      this.selectedCompanyOption = null
      this.focus('company')
    },

    clearSiteFilter() {
      this.selectedSiteOption = null
      this.focus('site')
    },

    buildData(company, site, email, password) {
      let data = new Object()

      if (company && site) {
        data.companyId = company
        data.siteId = site
      } else if (email && password) {
        data.email = email
        data.password = password
      }

      return data
    },

    async getRecognitionGateways() {
      this.isBusy = true

      const url = `${config.default.urlBase}/customers-recognition-gateways`
      const data = this.buildData(this.selectedCompanyOption, this.selectedSiteOption, this.email, this.password)

      try {
        const response = await axios({
          url,
          method: 'post',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusy = false

          this.items = response.data.items
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false
        this.isLoading = false

        this.items = []

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }
        if (error.response.status == 400) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }

        if (error.response.status == 401) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }

        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }
      }
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        this.selectedCompanyOption = null
        this.optionsCompany = []

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            if (!this.selectedCompanyOption) {
              this.selectedCompanyOption = this.optionsCompany[0].value
            }

            this.getSitesByCompany(this.selectedCompanyOption)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        this.optionsSite = []
        this.selectedSiteOption = null

        if (response.status == 200) {
          this.optionsSite = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} - ${item.name}`
              }
            })
          )

          if (this.optionsSite.length == 1) {
            this.selectedSiteOption = this.optionsSite[0].value

            await this.getRecognitionGateways()
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsSite = []

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }
        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }
      }
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')
    await this.getCompanies(this.currentPage)
    this.isLoading = false
  }
}
